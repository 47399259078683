/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/plugins/aaAirportAutoComplete.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/plugins/aa-airport-auto-complete.js
**/

//Requires jQuery UI
;(function($j){
    function aaAirportAutoComplete(targetFields, onlyAirportsIfNotNull, options, showVenezuelaAirports) {
        var defaults = {
                minLength: 3,
                source: function(request, response) {
                    $j.ajax({
                        type: "GET",
                        url: "/home/ajax/airportLookup",
                        contentType: 'application/json',
                        dataType: "json",
                        data: {
                            searchText: request.term,
                            onlyAirportsIfNotNull: onlyAirportsIfNotNull,
                            showVenezuelaAirports: showVenezuelaAirports
                        },
                        success: function(data) {
                            response($j.map(data, function(item) {
                                return {
                                    label: item.code + " - " + item.name + (item.countryCode == "MO" || item.countryCode == "TW" || item.countryCode == "HK" || item.countryName == "" ? "" : ", " + (item.countryCode == "US" ? item.stateCode : item.countryName)),
                                    value: item.code
                                };
                            }));
                        }
                    });
                },
                messages: {
                    noResults: function() {
                        return AAcom.prototype.getProperty('autoComplete.noResult');
                    },
                    results: function(count) {
                        var resultMessage = "";
                        if(count === 1){
                            resultMessage = AAcom.prototype.getProperty('autoComplete.oneResult');
                        }else{
                            resultMessage = AAcom.prototype.getProperty('autoComplete.manyResult');
                            resultMessage = resultMessage.replace("{0}", count);
                        }
                        return resultMessage;
                    }
                },
                select: function(event, ui) {
                    /* Android touch issue workaround to force selected value to display and fixes Rebrand defect #311 */
                    $j(this).blur().focus();
                    $j('#ariaLiveReaderContainer').text(ui.item.label + AAcom.prototype.getProperty('global.text.selected'));
                },
                focus: function(event, ui) {
                    $j('#ariaLiveReaderContainer').text(ui.item.label);
                }
            };

        var settings = $j.extend(defaults, options);

        $j(targetFields).autocomplete(settings)
            .attr({autocomplete : 'off', autocorrect : 'off'});
    }

    //----------------------------------------------------------------------------------------------------------------------------------
    //Register with jQuery as a plugin
    //----------------------------------------------------------------------------------------------------------------------------------
    $j.fn.aaAirportAutoComplete = function (onlyAirportsIfNotNull, options, showVenezuelaAirports) {
        return this.each(function() {
            new aaAirportAutoComplete(this, onlyAirportsIfNotNull, options, showVenezuelaAirports);
        });
    };
}(jQuery));
